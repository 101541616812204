import { createEvent, guard, is } from 'effector';
export function condition(_ref) {
  var {
    if: test,
    then: thenBranch,
    else: elseBranch,
    source = createEvent({
      name: "source",
      sid: "epke23"
    }, {
      name: "source",
      sid: "epke23"
    })
  } = _ref;
  var checker = is.unit(test) || isFunction(test) ? test : value => value === test;

  if (thenBranch) {
    guard({
      and: [{
        source,
        filter: checker,
        target: thenBranch
      }],
      or: {
        sid: "9tug3k"
      }
    });
  }

  if (elseBranch) {
    guard({
      and: [{
        source,
        filter: inverse(checker),
        target: elseBranch
      }],
      or: {
        sid: "a9shb1"
      }
    });
  }

  return source;
}

function isFunction(value) {
  return typeof value === 'function';
}

function inverse(fnOrUnit) {
  if (is.unit(fnOrUnit)) {
    return fnOrUnit.map(value => !value);
  }

  return value => !fnOrUnit(value);
}