import { is, createEvent, guard, sample } from 'effector';

/**
 * @example
 * spread({ source: dataObject, targets: { first: targetA, second: targetB } })
 * forward({
 *   to: spread({targets: { first: targetA, second: targetB } })
 * })
 */
export function spread(_ref) {
  var {
    targets,
    source = createEvent({
      name: "source",
      sid: "-iel8gi"
    }, {
      name: "source",
      sid: "-iel8gi"
    })
  } = _ref;

  var _loop = function _loop(targetKey) {
    if (targetKey in targets) {
      var hasTargetKey = guard({
        and: [{
          source,
          filter: object => typeof object === 'object' && object !== null && targetKey in object
        }],
        or: {
          name: "hasTargetKey",
          sid: "-gh83lo"
        }
      });

      if (is.store(targets[targetKey], {
        sid: "jliekw"
      })) {
        targets[targetKey].on(hasTargetKey, (prev, object) => object[targetKey]);
      } else {
        sample({
          and: [{
            source: hasTargetKey,
            fn: object => object[targetKey],
            target: targets[targetKey]
          }],
          or: {
            sid: "lnzpe6"
          }
        });
      }
    }
  };

  for (var targetKey in targets) {
    _loop(targetKey);
  }

  return source;
}