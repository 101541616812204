import { combine } from 'effector';
export function format(strings) {
  for (var _len = arguments.length, stores = new Array(_len > 1 ? _len - 1 : 0), _key = 1; _key < _len; _key++) {
    stores[_key - 1] = arguments[_key];
  }

  return combine({
    and: [stores, stores => strings.reduce((acc, value, index) => acc.concat(isLastElement(strings, index) ? value : "".concat(value).concat(toString(stores[index]))), '')],
    or: {
      sid: "-yrbthp"
    }
  });
}

function toString(value) {
  if (Array.isArray(value)) {
    return value.map(value => String(value)).join(', ');
  }

  return String(value);
}

function isLastElement(array, index) {
  return index === array.length - 1;
}