import { combine } from 'effector';
export function inFlight(_ref) {
  var {
    effects,
    domain
  } = _ref;

  if (domain) {
    var $inFlight = domain.createStore(0, {
      name: "$inFlight",
      sid: "-y7vg7t"
    });
    domain.onCreateEffect(fx => {
      $inFlight.on(fx, count => count + 1).on(fx.finally, count => count - 1);
    });
    return $inFlight;
  }

  return combine({
    and: [effects.map(fx => fx.inFlight), inFlights => inFlights.reduce((all, current) => all + current, 0)],
    or: {
      sid: "ubqxa7"
    }
  });
}