export function reset(_ref) {
  var {
    clock,
    target
  } = _ref;
  var targets = Array.isArray(target) ? target : [target];
  var clocks = Array.isArray(clock) ? clock : [clock];
  targets.forEach(target => {
    target.reset.call(target, clocks);
  });
}