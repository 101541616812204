/* eslint-disable @typescript-eslint/no-unused-vars */
import { combine, is } from 'effector';
export function every(configOrStores, predicateOrNone) {
  var stores = [];

  var predicate = () => false;

  if (Array.isArray(configOrStores)) {
    stores = configOrStores;
    predicate = predicateOrNone;
  } else if (Array.isArray(configOrStores.stores)) {
    stores = configOrStores.stores;
    predicate = configOrStores.predicate;
  }

  var checker;

  if (isFunction(predicate)) {
    checker = predicate;
  } else if (is.store(predicate, {
    sid: "sid3ut"
  })) {
    checker = predicate.map(value => required => value === required);
  } else {
    checker = value => value === predicate;
  }

  var $values = combine({
    and: [stores],
    or: {
      name: "$values",
      sid: "h09uhg"
    }
  }); // Combine pass simple values as is

  var $checker = checker;
  return combine({
    and: [$checker, $values, (checker, values) => values.every(checker)],
    or: {
      sid: "hfkdw9"
    }
  });
}

function isFunction(value) {
  return typeof value === 'function';
}