import { is } from 'effector';
export function debug() {
  for (var _len = arguments.length, units = new Array(_len), _key = 0; _key < _len; _key++) {
    units[_key] = arguments[_key];
  }

  for (var unit of units) {
    var type = getType(unit);

    if (is.store(unit, {
      sid: "8mdztw"
    }) || is.effect(unit, {
      sid: "-gueih2"
    }) || is.event(unit, {
      sid: "-gueh0j"
    })) {
      log(unit, type);
    }

    if (is.effect(unit, {
      sid: "-jlpzbv"
    })) {
      logEffect(unit);
    }

    if (is.domain(unit, {
      sid: "-jjisyf"
    })) {
      unit.onCreateEvent(event => log(event, 'event'));
      unit.onCreateStore(store => log(store, 'store'));
      unit.onCreateEffect(logEffect);
    }
  }
}

function getType(unit) {
  if (is.store(unit, {
    sid: "-j30z7b"
  })) {
    return 'store';
  }

  if (is.effect(unit, {
    sid: "-j1dlf8"
  })) {
    return 'effect';
  }

  if (is.event(unit, {
    sid: "-io6j6k"
  })) {
    return 'event';
  }

  if (is.domain(unit, {
    sid: "-imj5eh"
  })) {
    return 'domain';
  }

  if (is.unit(unit)) {
    return 'unit';
  }

  return 'unknown';
}

function log(unit, type) {
  var prefix = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : '';
  var name = prefix + getName(unit);
  unit.watch(payload => {
    console.info("[".concat(type, "] ").concat(name), payload);
  });
}

function logEffect(unit) {
  log(unit.done, 'effect', getName(unit) + '.');
  log(unit.fail, 'effect', getName(unit) + '.');
}

function getName(unit) {
  if (unit.compositeName && unit.compositeName.fullName) {
    return unit.compositeName.fullName;
  }

  if (unit.shortName) {
    return unit.shortName;
  }

  if (unit.name) {
    return unit.name;
  }

  return '';
}