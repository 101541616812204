import { createEffect, forward, restore } from 'effector';

var defaultNow = () => Date.now();

export function time(_ref) {
  var {
    clock,
    getNow,
    initial
  } = _ref;
  var timeReader = getNow !== null && getNow !== void 0 ? getNow : defaultNow;
  var readNowFx = createEffect(timeReader, {
    name: "readNowFx",
    sid: "459ysb"
  });
  var $time = restore(readNowFx, initial !== null && initial !== void 0 ? initial : timeReader(), {
    name: "$time",
    sid: "-ys9vce"
  });
  forward({
    and: {
      from: clock,
      to: readNowFx
    },
    or: {
      sid: "rdje9v"
    }
  });
  return $time;
}