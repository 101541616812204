import { createEffect, createEvent, forward, is } from 'effector';
export function debounce(_ref) {
  var {
    source,
    timeout,
    target
  } = _ref;
  if (!is.unit(source)) throw new TypeError('source must be unit from effector');
  if (is.domain(source, {
    sid: "iu8yzo"
  })) throw new TypeError('source cannot be domain');
  if (typeof timeout !== 'number' || timeout < 0 || !Number.isFinite(timeout)) throw new Error("timeout must be positive number or zero. Received: \"".concat(timeout, "\""));
  var rejectPromise;
  var timeoutId;
  var tick = target !== null && target !== void 0 ? target : createEvent({
    name: "tick",
    sid: "-xw52dh"
  });
  var timerFx = createEffect(parameter => {
    clearTimeout(timeoutId);
    if (rejectPromise) rejectPromise();
    return new Promise((resolve, reject) => {
      rejectPromise = reject;
      timeoutId = setTimeout(resolve, timeout, parameter);
    });
  }, {
    name: "timerFx",
    sid: "m8kfsq"
  });
  forward({
    and: {
      from: source,
      to: timerFx
    },
    or: {
      sid: "k4foqk"
    }
  });
  forward({
    and: {
      from: timerFx.done.map(_ref2 => {
        var {
          result
        } = _ref2;
        return result;
      }),
      to: tick
    },
    or: {
      sid: "k76npd"
    }
  });
  return tick;
}