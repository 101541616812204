import { createStore, sample } from 'effector';
export function snapshot(_ref) {
  var {
    source,
    clock,
    fn = value => value
  } = _ref;
  var defaultValue = fn(source.defaultState);
  var onSnapshot = clock ? sample({
    and: [source, clock, fn],
    or: {
      name: "onSnapshot",
      sid: "-auitd0"
    }
  }) : sample({
    and: [{
      source,
      fn
    }],
    or: {
      name: "onSnapshot",
      sid: "-auir6n"
    }
  });
  var $snapshot = createStore(defaultValue, {
    name: "$snapshot",
    sid: "-7zyoaf"
  });
  $snapshot.on(onSnapshot, (_, value) => value);
  return $snapshot;
}