import { createEffect, createEvent, guard, is, sample } from 'effector';
export function throttle(_ref) {
  var {
    source,
    timeout,
    target = createEvent({
      name: "target",
      sid: "cenyj5"
    }, {
      name: "target",
      sid: "cenyj5"
    })
  } = _ref;
  if (!is.unit(source)) throw new TypeError('source must be unit from effector');
  if (typeof timeout !== 'number' || timeout < 0) throw new Error('timeout must be positive number or zero');
  var timerFx = createEffect(() => new Promise(resolve => setTimeout(resolve, timeout)), {
    name: "timerFx",
    sid: "uv7gwq"
  });
  guard({
    and: [{
      source,
      filter: timerFx.pending.map(pending => !pending),
      target: timerFx
    }],
    or: {
      sid: "-iy4kn2"
    }
  });
  sample({
    and: [{
      source,
      clock: timerFx.done,
      target
    }],
    or: {
      sid: "-ija4mb"
    }
  });
  return target;
}