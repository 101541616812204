export function reshape(_ref) {
  var {
    source,
    shape
  } = _ref;
  var result = {};

  for (var key in shape) {
    if (key in shape) {
      (function () {
        var fn = shape[key];
        result[key] = source.map(state => {
          var result = fn(state);
          return result === undefined ? null : result;
        });
      })();
    }
  }

  return result;
}